.gallery {
  background-color: #fafafa;

  .navbar {
    background-color: #fff;
    border-bottom: 1px solid #dbdbdb !important;

    .navbar-text {
      margin-left: 0;
    }

    &.navbar-default {
      border: none;
      border-radius: 0;
    }
  }

  .photos  {
    margin-bottom: 60px;
  }

  .thumbnail {
    padding: 0;
    background-color: #fff;
    border: 1px solid #efefef;
    max-width: 600px;
    margin: auto;
    margin-top: 60px;

    .caption {
      margin: 15px 20px 15px;
      padding: 0;
    }

    img {
      max-width: 100%;
      border-radius: 4px 4px 0 0;
    }

    .imageWrap {
      position: relative;

      .img {
        position: relative;
      }

    }

    .imgOverlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

  }

}