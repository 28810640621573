@import "node_modules/bootstrap/less/bootstrap";

// @import "./spark/spark";
@import "./../../../vendor/laravel/spark/resources/assets/less/spark";

@import "_login";
@import "_gallery";
@import "_sociallogin";

.medium-editor-hidden {
  display: none !important;
}

.medium-editor-element {
  min-height: 10em;
  line-height: 1.5em;
  overflow-y: auto;
}

.photos {
  margin-top: 2em;
}

.photoWrap {
  position: relative;

  img {
    max-width: 100%;
  }

  .deletePhoto {
    position: absolute;
    top: -.75em;
    right: .1em;
    font-size: 2em;
    color: #ce4844;
  }
}
