
/*
    Note: It is best to use a less version of this file ( see http://css2less.cc
    For the media queries use @screen-sm-min instead of 768px.
    For .omb_spanOr use @body-bg instead of white.
*/

@media (min-width: 768px) {
  .omb_row-sm-offset-3 div:first-child[class*="col-"] {
    margin-left: 25%;
  }
}

.omb_login .omb_authTitle {
  text-align: center;
  line-height: 300%;
}

.omb_login .omb_socialButtons a {
  color: white; // In yourUse @body-bg
  opacity:0.9;
}
.omb_login .omb_socialButtons a:hover {
  color: white;
  opacity:1;
}
.omb_login .omb_socialButtons .omb_btn-facebook {background: #3b5998;}
.omb_login .omb_socialButtons .omb_btn-twitter {background: #00aced;}
.omb_login .omb_socialButtons .omb_btn-google {background: #c32f10;}


.omb_login .omb_loginOr {
  position: relative;
  font-size: 1.5em;
  color: #aaa;
  margin-top: 1em;
  margin-bottom: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.omb_login .omb_loginOr .omb_hrOr {
  background-color: #cdcdcd;
  height: 1px;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.omb_login .omb_loginOr .omb_spanOr {
  display: block;
  position: absolute;
  left: 50%;
  top: -0.6em;
  margin-left: -1.5em;
  background-color: white;
  width: 3em;
  text-align: center;
}

.omb_login .omb_loginForm .input-group.i {
  width: 2em;
}
.omb_login .omb_loginForm  .help-block {
  color: red;
}

.omb_socialButtons .btn-lg {
  padding: 10px;
}


@media (min-width: 768px) {
  .omb_login .omb_forgotPwd {
    text-align: right;
    margin-top:10px;
  }
}