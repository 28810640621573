html, body {
  height: 100%;
}

body.login.frontend {
  //background: #005C97; /* fallback for old browsers */
  //background: -webkit-linear-gradient(to left, #005C97 , #363795); /* Chrome 10-25, Safari 5.1-6 */
  //background: linear-gradient(to left, #005C97 , #363795); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: #fafafa;
}

.frontend {

  .jumbotron {
    text-align: center;
    width: 30rem;
    border-radius: 0.5rem;
    top: 0;
    left: 0;
    right: 0;
    position: absolute;
    margin: 4rem auto;
    background-color: #fff;
    border: 1px solid #efefef;
    padding: 2rem;
  }

  .container .glyphicon-list-alt {
    font-size: 10rem;
    margin-top: 3rem;
    color: #f96145;
  }

  input {
    width: 100%;
    margin-bottom: 1.4rem;
    padding: 1rem;
    background-color: #fff;
    border: 1px solid #dbdbdb;;
    border-radius: 3px;
  }
  h2 {
    margin-bottom: 3rem;
    font-weight: bold;
    color: #ababab;
  }
  .btn {
    border-radius: 3px;
    background: #3897f0;
    border-color: #3897f0;
    color: #fff;
    width: 100%;
    height: 44px;
  }
  .btn .glyphicon {
    font-size: 3rem;
    color: #fff;
  }

  .box {
    position: relative;
    bottom: 0;
    left: 0;
    margin-bottom: 2rem;
  }

  p.help-block {
    font-size: 1em;
    margin-top: 0;
    color: #ce4844;
  }

  input.has-error {
    border: 1px solid rgba(206, 72, 68, .8);
  }
}